import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A Knowledge Base to Give Instant Answers to Your Customers
Use Crisp for free
(No credit card required)
A dedicated knowledge base to help customers
Did you know that people visiting a self service help desk tend to buy 2x more than others? It’s because it allows them to be self-taught about your product and features.`}</p>
    <p>{`With our knowledge base software, you can build a self-service Help Desk with content to help your users understand your product. Set up your custom domain and your own colors to unify your branding.`}</p>
    <p>{`Organize your knowledge base by categories
Because people are humans
Design your articles with meaningful categories. Using icons and colorful titles, you can guide users to the right resource.`}</p>
    <p>{`Our Knowledge Base software provides ready-to-use icons for organizing your different sections. Of course, you can also use your own.`}</p>
    <p>{`Better help with rich content
Create high-value articles with rich content.
Our Help Desk software can be used for both technical end-users (developers) or non-technical people (B2C products).`}</p>
    <p>{`From Youtube videos, code preview, images, warnings to complex articles, Our help desk software provides the toolkit you need to create the content your users deserve.`}</p>
    <p>{`Build Multilingual knowledge base articles
As always, Our Knowledge Base software comes with multilingual compatibility. Route users to the right article according to their language.`}</p>
    <p>{`Designed for search engines
Let your users find your answers from Google
Most users type their queries directly from search engines. That’s why we designed our Knowledge Base around search engines, to let your users find your content directly.
Browse Knowledge Base from Crisp Apps
Reply on the go
Reply to your users faster by browsing your help desk directly from Crisp Apps.`}</p>
    <p>{`It allows agents to be more productive, as they avoid having to browse the knowledge base through a different tab.`}</p>
    <p>{`It’s compatible with Crisp Mobile Apps (Android & iOS) as well!
Ready to improve your customer engagement?Try Crisp for freeRequest a demo`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      